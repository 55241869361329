import {
  transformSlug,
  convertToNumber,
  convertToBoolean,
  getValueFromObject,
  toQueryString
} from './common';
import { hostname } from './url';
import searchFormConditionsValues from '../../defaultData/searchFormConditionsValues';
import config from '../../config';

const convertRoomsData = data => {
  if (typeof data === 'undefined') {
    return;
  }

  return typeof data === 'object'
    ? data
    : [data.split('-')[0] !== '5' ? data.split('-')[0] : '5+'];
};

const convertLocations = (data, dataForTransform, citySlug) => {
  if (typeof data === 'undefined') {
    return;
  }

  return !(typeof data === 'object')
    ? new Array(
        getValueFromObject(dataForTransform, `${citySlug}/${data}`, 'id') || ''
      )
    : data;
};

const convertBuildingTypes = data => {
  if (typeof data === 'undefined') {
    return;
  }

  const result = !(typeof data === 'object') ? new Array(data) : data;

  return result.map(i => i.toUpperCase());
};

const createUrlRegExps = data => {
  if (!data || typeof data !== 'object') {
    return false;
  }

  function getValues(obj, key) {
    if (!obj) {
      return;
    }
    const keysArr = Object.keys(obj);
    let objects = [];

    for (let i = 0; i < keysArr.length; i += 1) {
      if (typeof obj[keysArr[i]] === 'object') {
        objects = objects.concat(getValues(obj[keysArr[i]], key));
      } else if (keysArr[i] === key) {
        objects.push(obj[keysArr[i]]);
      }
    }

    return objects;
  }
  const cityReg = `(${getValues(data.cities, 'slug').join('|')})`;

  return {
    city: cityReg,
    location: `(${getValues(data.locations, 'slug')
      .join('|')
      .replace(new RegExp(`${cityReg}/`, 'g'), '')})`,
    leased: '(kiraye|alqi-satqi)',
    category: `(${getValues(data.categories, 'slug').join('|')})`,
    room: '(1-otaqli|2-otaqli|3-otaqli|4-otaqli|5-otaqli)'
  };
};

export const getCategoryUrl = (tabSlug, slug, lang = 'az') =>
  `${config.protocol}://${hostname(lang, config.host)}/${tabSlug}/${slug}`;

export const locationGroupDispatcher = (locationGroupId, parent, t) => {
  let groupName = '';
  if (parent) {
    groupName = parent.fullName;
  } else {
    switch (locationGroupId) {
      case '1':
        groupName = t('search.locations_search.location_groups.metro');
        break;
      case '2':
        groupName = null;
        break;
      case '3':
        groupName = null;
        break;
      case '4':
        groupName = t('search.locations_search.location_groups.landmark');
        break;
      default:
        break;
    }
  }

  return groupName;
};

export const convertValues = values => {
  return {
    cityId: +values.city_id ? values.city_id : undefined,
    categoryId: values.category_id,
    leased: values.leased === 'true',
    roomIds:
      typeof values.room_ids === 'object' && values.room_ids.length
        ? values.room_ids
        : undefined,
    priceFrom: convertToNumber(values.price_from),
    priceTo: convertToNumber(values.price_to),
    areaFrom: convertToNumber(values.area_from),
    areaTo: convertToNumber(values.area_to),
    landAreaFrom: convertToNumber(values.land_area_from),
    landAreaTo: convertToNumber(values.land_area_to),
    paidDaily:
      values.paid_daily && values.paid_daily.length
        ? values.paid_daily
        : undefined,
    hasBillOfSale: convertToBoolean(values.has_bill_of_sale),
    hasMortgage: convertToBoolean(values.has_mortgage),
    onlyResidences: convertToBoolean(values.only_residences),
    hasRepair:
      values.has_repair && values.has_repair.length
        ? values.has_repair
        : undefined,
    floorFrom: convertToNumber(values.floor_from),
    floorTo: convertToNumber(values.floor_to),
    floorFirst: values.floor_first ? values.floor_first : undefined,
    floorLast: values.floor_last,
    locationIds:
      typeof values.location_ids === 'object' && values.location_ids.length
        ? values.location_ids
        : undefined,
    buildingType:
      typeof values.building_type === 'object' && values.building_type.length
        ? values.building_type
        : undefined
  };
};

const checkConditionValue = (conditionValue, is, value) => {
  if (typeof is === 'object' && !!is && !Array.isArray(is)) {
    const isPassCheck = Object.keys(is).every(elem => {
      return is[elem].includes(conditionValue[elem]);
    });

    return isPassCheck ? value : undefined;
  }
  return is.includes(conditionValue) ? value : undefined;
};

export const convertSearchValues = (values, forUrl = false, sort) => {
  if (forUrl) {
    return {
      city_id: +values.city_id ? values.city_id : undefined,
      category_id: values.category_id,
      leased: values.leased === 'true',
      room_ids: checkConditionValue(
        values.category_id,
        searchFormConditionsValues.room_ids,
        values.room_ids
      ),
      price_from: convertToNumber(values.price_from),
      price_to: convertToNumber(values.price_to),
      area_from: convertToNumber(values.area_from),
      area_to: convertToNumber(values.area_to),
      paid_daily: checkConditionValue(
        values.leased,
        searchFormConditionsValues.paid_daily,
        values.paid_daily && values.paid_daily.length
          ? values.paid_daily[0] === 'true'
          : undefined
      ),
      land_area_from: checkConditionValue(
        values.category_id,
        searchFormConditionsValues.land_area,
        convertToNumber(values.land_area_from)
      ),
      land_area_to: checkConditionValue(
        values.category_id,
        searchFormConditionsValues.land_area,
        convertToNumber(values.land_area_to)
      ),
      has_bill_of_sale: checkConditionValue(
        values.leased,
        searchFormConditionsValues.has_bill_of_sale,
        convertToBoolean(values.has_bill_of_sale)
      ),
      only_residences: checkConditionValue(
        values,
        searchFormConditionsValues.only_residences,
        convertToBoolean(values.only_residences)
      ),
      has_mortgage: checkConditionValue(
        values.leased,
        searchFormConditionsValues.has_mortgage,
        convertToBoolean(values.has_mortgage)
      ),
      has_repair: checkConditionValue(
        values.category_id,
        searchFormConditionsValues.has_repair,
        values.has_repair && values.has_repair.length
          ? values.has_repair === 'true'
          : undefined
      ),
      floor_from: checkConditionValue(
        values.category_id,
        searchFormConditionsValues.floors,
        convertToNumber(values.floor_from)
      ),
      floor_to: checkConditionValue(
        values.category_id,
        searchFormConditionsValues.floors,
        convertToNumber(values.floor_to)
      ),
      floor_first: checkConditionValue(
        values.category_id,
        searchFormConditionsValues.floors,
        values.floor_first ? !values.floor_first : undefined
      ),
      floor_last: checkConditionValue(
        values.category_id,
        searchFormConditionsValues.floors,
        values.floor_last ? values.floor_last === 'true' : values.floor_last
      ),
      location_ids: values.location_ids,
      building_type: checkConditionValue(
        values.category_id,
        searchFormConditionsValues.building_type,
        values.building_type
          ? values.building_type.map(type => type.toLowerCase())
          : values.building_type
      ),
      sorting: getValueFromObject(sort, values.sorting, 'params')
    };
  }
  return {
    cityId: +values.city_id ? values.city_id : undefined,
    categoryId: values.category_id,
    leased: values.leased === 'true',
    roomIds: checkConditionValue(
      values.category_id,
      searchFormConditionsValues.room_ids,
      typeof values.room_ids === 'object' && values.room_ids.length
        ? values.room_ids
        : undefined
    ),
    priceFrom: convertToNumber(values.price_from),
    priceTo: convertToNumber(values.price_to),
    areaFrom: convertToNumber(values.area_from),
    areaTo: convertToNumber(values.area_to),
    paidDaily: checkConditionValue(
      values.leased,
      searchFormConditionsValues.paid_daily,
      values.paid_daily && values.paid_daily.length
        ? values.paid_daily[0] === 'true'
        : undefined
    ),
    landAreaFrom: checkConditionValue(
      values.category_id,
      searchFormConditionsValues.land_area,
      convertToNumber(values.land_area_from)
    ),
    landAreaTo: checkConditionValue(
      values.category_id,
      searchFormConditionsValues.land_area,
      convertToNumber(values.land_area_to)
    ),
    hasBillOfSale: checkConditionValue(
      values.leased,
      searchFormConditionsValues.has_bill_of_sale,
      convertToBoolean(values.has_bill_of_sale)
    ),
    onlyResidences: checkConditionValue(
      values,
      searchFormConditionsValues.only_residences,
      convertToBoolean(values.only_residences)
    ),
    hasMortgage: checkConditionValue(
      values.leased,
      searchFormConditionsValues.has_bill_of_sale,
      convertToBoolean(values.has_mortgage)
    ),
    hasRepair: checkConditionValue(
      values.category_id,
      searchFormConditionsValues.has_repair,
      values.has_repair && values.has_repair.length
        ? values.has_repair === 'true'
        : undefined
    ),
    floorFrom: checkConditionValue(
      values.category_id,
      searchFormConditionsValues.floors,
      convertToNumber(values.floor_from)
    ),
    floorTo: checkConditionValue(
      values.category_id,
      searchFormConditionsValues.floors,
      convertToNumber(values.floor_to)
    ),
    floorFirst: checkConditionValue(
      values.category_id,
      searchFormConditionsValues.floors,
      values.floor_first ? !values.floor_first : undefined
    ),
    floorLast: checkConditionValue(
      values.category_id,
      searchFormConditionsValues.floors,
      values.floor_last ? values.floor_last === 'true' : values.floor_last
    ),
    locationIds:
      typeof values.location_ids === 'object' && values.location_ids.length
        ? values.location_ids
        : undefined,
    buildingType: checkConditionValue(
      values.category_id,
      searchFormConditionsValues.building_type,
      typeof values.building_type === 'object' && values.building_type.length
        ? values.building_type
        : undefined
    )
  };
};

export const fromObjToUrl = (
  values,
  { city, categories, rooms, sort },
  sortFromUrl
) => {
  const editValues = Object.assign({}, convertSearchValues(values, true, sort));

  const urlSegmentsList = ['city_id', 'leased', 'category_id'];

  const fromDataToUrl = [
    city ? city.slug : '',
    values.location_ids && values.location_ids.length === 1
      ? transformSlug(
          getValueFromObject(
            city ? city.locations : {},
            editValues.location_ids[0],
            'slug'
          )
        )
      : undefined,
    values.leased === 'true' ? 'kiraye' : 'alqi-satqi',
    getValueFromObject(categories, editValues.category_id, 'slug'),
    values.room_ids && values.room_ids.length === 1
      ? getValueFromObject(rooms, `${editValues.room_ids[0]}`, 'slug')
      : undefined
  ].filter(el => {
    return el != null;
  });

  const queriesData = Object.keys(editValues)
    .map(key => {
      if (key === 'sorting') {
        editValues[key] = sortFromUrl;
      }

      return key;
    })
    .filter(key => {
      if (
        (key === 'room_ids' || key === 'location_ids') &&
        editValues[key] &&
        editValues[key].length <= 1
      ) {
        return false;
      }

      return !urlSegmentsList.includes(key) && editValues[key] !== undefined;
    })
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: editValues[key]
      };
    }, {});

  const query = concatSign =>
    !Object.keys(queriesData).length
      ? ''
      : `${concatSign}${toQueryString(queriesData)}`;

  return {
    href: `/[...search]?url=${fromDataToUrl.join('/')}${query('&')}`.replace(
      /\/\//g,
      '/'
    ),
    as: `/${fromDataToUrl.join('/')}${query('?')}`.replace(/\/\//g, '/')
  };
};

export const fromUrlToObj = (data, query) => {
  const { city, location, leased, category, room } = createUrlRegExps(
    data.data
  );
  const keys = [
    'cityId',
    'location_ids[]',
    'leased',
    'categoryId',
    'room_ids[]'
  ];
  const regexp = new RegExp(
    `^${city}?\\/?${location}?\\/?${leased}?\\/?${category}?\\/?${room}?$`,
    'g'
  );
  const matchObj = regexp.exec(query.search.join('/'));

  if (!matchObj) {
    return {
      noMatch: true
    };
  }
  const newArr = keys.map((item, index) => {
    const obj = {};
    obj[item] = matchObj[index + 1];
    return obj;
  });
  let result = {};

  for (let i = 0; i < newArr.length; i += 1) {
    result[Object.keys(newArr[i])[0]] = newArr[i][Object.keys(newArr[i])[0]];
  }

  result = Object.assign({}, result, query);

  return {
    filter: {
      cityId: getValueFromObject(data.data.cities, result.cityId, 'id'),
      categoryId:
        getValueFromObject(data.data.categories, result.categoryId, 'id') ||
        '1',
      leased:
        typeof result.leased === 'undefined'
          ? false
          : result.leased === 'kiraye',
      roomIds: convertRoomsData(result['room_ids[]']),
      priceFrom:
        typeof result.price_from !== 'undefined'
          ? +result.price_from
          : result.price_from,
      priceTo:
        typeof result.price_to !== 'undefined'
          ? +result.price_to
          : result.price_to,
      areaFrom:
        typeof result.area_from !== 'undefined'
          ? +result.area_from
          : result.area_from,
      areaTo:
        typeof result.area_to !== 'undefined'
          ? +result.area_to
          : result.area_to,
      landAreaFrom:
        typeof result.land_area_from !== 'undefined'
          ? +result.land_area_from
          : result.land_area_from,
      landAreaTo:
        typeof result.land_area_to !== 'undefined'
          ? +result.land_area_to
          : result.land_area_to,
      paidDaily: result.paid_daily ? result.paid_daily === 'true' : undefined,
      onlyResidences: result.only_residences
        ? result.only_residences === 'true'
        : undefined,
      hasBillOfSale: result.has_bill_of_sale
        ? result.has_bill_of_sale === 'true'
        : undefined,
      hasMortgage: result.has_mortgage
        ? result.has_mortgage === 'true'
        : undefined,
      hasRepair: result.has_repair ? result.has_repair === 'true' : undefined,
      floorFrom:
        typeof result.floor_from !== 'undefined'
          ? +result.floor_from
          : result.floor_from,
      floorTo:
        typeof result.floor_to !== 'undefined'
          ? +result.floor_to
          : result.floor_to,
      floorFirst: result.floor_first
        ? result.floor_first === 'true'
        : result.floor_first,
      floorLast: result.floor_last
        ? result.floor_last === 'true'
        : result.floor_last,
      locationIds: convertLocations(
        result['location_ids[]'],
        data.data.locations,
        result.cityId || 'baki'
      ),
      buildingType: convertBuildingTypes(result['building_type[]'])
    },
    sorting: getValueFromObject(
      data.data.sort,
      result.sorting && result.sorting.replace(/\s/g, '+'),
      'name'
    )
  };
};
